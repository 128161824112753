const SvgNotFound = ({ className }) => (
  <svg
    className={className}
    width="752"
    height="598"
    viewBox="0 0 752 598"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.948 346.359H672.661C672.661 346.359 679.374 375.144 653.844 376.225C628.313 377.305 573.148 383.682 573.148 395.426C573.148 407.17 595.583 408.773 622.47 426.894C649.357 445.015 657.878 551.094 512.626 553.22C367.339 555.346 126.574 493.525 181.704 468.991C236.87 444.458 266.435 424.211 220.731 412.467C175.026 400.723 -61.7738 376.225 57.948 346.359Z"
      fill="#25345A"
    />
    <path
      d="M136.626 443.377C173.394 443.377 203.2 436.933 203.2 428.984C203.2 421.036 173.394 414.592 136.626 414.592C99.8584 414.592 70.0522 421.036 70.0522 428.984C70.0522 436.933 99.8584 443.377 136.626 443.377Z"
      fill="#25345A"
    />
    <path
      d="M628.139 406.054C649.981 406.054 667.687 402.232 667.687 397.516C667.687 392.801 649.981 388.979 628.139 388.979C606.298 388.979 588.592 392.801 588.592 397.516C588.592 402.232 606.298 406.054 628.139 406.054Z"
      fill="#25345A"
    />
    <path
      d="M77.2522 384.275H727.131C727.131 384.275 749.426 349.462 681.044 329.563C647.652 319.84 638.852 284.713 642.365 237.563C645.357 197.766 581.391 172.919 526.365 171.665C471.339 170.41 469.844 54.7827 386.574 73.4266C303.304 92.0705 286.957 185.325 245.322 185.325C203.687 185.325 138.261 197.766 115.93 218.884C93.6348 240.037 44.5566 304.681 34.1218 323.325C23.687 341.969 -3.02606 380.546 77.2522 384.275Z"
      fill="#0F1B37"
    />
    <path
      d="M71.3044 384.274H692.383C692.383 384.274 712.487 352.911 650.852 334.964C620.765 326.217 612.835 294.574 616 252.059C618.678 216.2 561.078 193.793 511.478 192.677C461.878 191.562 460.557 87.3652 385.531 104.162C310.504 120.959 295.757 204.979 258.226 204.979C220.696 204.979 161.739 216.165 141.67 235.227C121.565 254.289 77.3565 312.521 67.9652 329.318C58.5739 346.115 -1.04348 380.929 71.3044 384.274Z"
      fill="#1C2846"
    />
    <path
      d="M252.487 350.821V385.808H221.426V350.821H157.009V325.939L221.426 236.518H252.487V325.939H269.53V350.821H252.487ZM221.426 274.084L184 325.939H221.391V274.084H221.426Z"
      fill="#3468EF"
    />
    <path
      d="M260.313 350.821H243.27V385.808H212.209V350.821H147.792V325.939L212.209 236.518H243.27V325.939H260.313V350.821ZM212.209 274.084L174.818 325.939H212.209V274.084Z"
      fill="#36529C"
    />
    <path
      d="M547.896 184.523C522.678 183.025 519.722 176.717 519.722 176.717V175.497C519.722 174.731 519.096 174.104 518.33 174.104H515.896C515.13 174.104 514.504 174.731 514.504 175.497V241.152C514.504 241.919 515.13 242.546 515.896 242.546H518.33C519.096 242.546 519.722 241.919 519.722 241.152V212.716C523.061 211.879 527.026 211.182 528.626 211.984C531.583 213.482 547.896 216.444 551.617 210.485C553.044 208.185 560.417 199.682 565.704 197.87C574.122 194.978 583.513 190.447 583.513 190.447C583.513 190.447 573.113 186.022 547.896 184.523Z"
      fill="#3468EF"
    />
    <path
      d="M544.8 350.821V385.808H513.739V350.821H449.322V325.939L513.739 236.518H544.8V325.939H561.844V350.821H544.8ZM513.704 274.084L476.313 325.939H513.704V274.084Z"
      fill="#3468EF"
    />
    <path
      d="M552.626 350.821H535.583V385.808H504.522V350.821H440.104V325.939L504.522 236.518H535.583V325.939H552.626V350.821ZM504.522 274.084L467.131 325.939H504.522V274.084Z"
      fill="#36529C"
    />
    <path
      d="M308 403.337C309.913 396.577 327.27 391.21 348.661 390.966C348.557 385.077 348 382.01 347.548 380.477C320.313 380.964 298.087 388.77 294.157 398.842H293.913V399.539C293.67 400.34 293.531 401.177 293.531 402.013C293.531 402.849 293.67 403.686 293.913 404.487V405.707C296.835 404.034 301.426 402.501 308 403.337Z"
      fill="#36529C"
    />
    <path
      d="M392.383 403.128C398.087 402.78 402.122 404.278 404.765 405.846C405.426 406.23 406.017 406.648 406.539 407.066V404.487C406.783 403.686 406.922 402.849 406.922 402.013C406.922 401.177 406.783 400.34 406.539 399.539V398.842C406.539 398.842 406.435 398.842 406.296 398.842C402.296 388.631 379.513 380.755 351.757 380.477C352.348 383.021 352.661 386.54 352.73 390.966C373.496 391.349 390.261 396.542 392.383 403.128Z"
      fill="#36529C"
    />
    <path
      d="M352.278 418.148C351.583 418.148 350.922 418.148 350.226 418.148C330.018 418.148 313.113 413.618 308.87 407.554C301.113 406.195 296.522 408.495 294.018 410.621C295.652 421.912 320.209 430.833 350.226 430.833C351.409 430.833 352.557 430.833 353.705 430.798C353.774 423.236 352.905 419.716 352.278 418.148Z"
      fill="#36529C"
    />
    <path
      d="M391.791 407.276C388.278 412.852 374.191 417.173 356.522 418.044C357.391 420.971 357.809 425.188 357.774 430.694C382.887 429.405 402.748 421.843 406.052 412.259C404.383 410.168 400.139 406.474 391.791 407.276Z"
      fill="#36529C"
    />
    <path
      d="M308.87 407.554C308.174 406.578 307.826 405.568 307.826 404.557C307.826 404.139 307.896 403.721 308 403.337C301.426 402.501 296.835 404.069 293.913 405.777V409.436C293.913 409.854 293.948 410.237 294.017 410.656C296.522 408.495 301.113 406.195 308.87 407.554Z"
      fill="white"
    />
    <path
      d="M406.539 407.102C406.017 406.684 405.426 406.266 404.765 405.882C402.156 404.314 398.122 402.816 392.383 403.164C392.522 403.617 392.626 404.105 392.626 404.593C392.626 405.534 392.348 406.44 391.791 407.311C400.174 406.51 404.383 410.204 406.052 412.294C406.365 411.388 406.539 410.448 406.539 409.472V407.102Z"
      fill="white"
    />
    <path
      d="M350.226 390.931C351.061 390.931 351.896 390.931 352.731 390.966C352.661 386.505 352.313 382.986 351.757 380.477C351.235 380.477 350.748 380.477 350.226 380.477C349.322 380.477 348.452 380.477 347.583 380.511C348.035 382.045 348.591 385.077 348.696 391.001C349.183 390.966 349.705 390.931 350.226 390.931Z"
      fill="white"
    />
    <path
      d="M356.522 418.01C355.13 418.079 353.704 418.114 352.278 418.149C352.904 419.717 353.774 423.202 353.739 430.799C355.096 430.764 356.452 430.73 357.809 430.66C357.809 425.189 357.391 420.972 356.522 418.01Z"
      fill="white"
    />
    <path
      d="M382.817 268.056C389.669 270.879 396 275.13 401.948 280.845C407.861 286.56 412.556 294.576 415.965 304.786C419.409 315.101 421.148 326.95 421.148 340.401C421.148 354.027 419.374 366.015 415.965 376.226C412.522 386.541 407.861 394.451 401.948 400.166C396.035 405.882 389.704 410.063 382.922 412.747C376.069 415.569 368.696 416.894 360.904 416.894C353.217 416.894 345.948 415.535 339.2 412.747C332.452 410.029 326.017 405.882 320.069 400.166C314.156 394.451 309.356 386.541 305.948 376.226C302.504 366.015 300.765 354.062 300.765 340.401C300.765 326.88 302.539 314.997 305.948 304.786C309.391 294.576 314.156 286.56 320.069 280.845C325.983 275.13 332.417 270.844 339.304 268.056C346.052 265.233 353.322 263.909 361.009 263.909C368.556 263.874 375.826 265.233 382.817 268.056ZM360.974 290.533C341.53 290.533 331.791 307.191 331.791 340.401C331.791 373.612 341.565 390.269 360.974 390.269C380.417 390.269 390.052 373.612 390.052 340.401C390.052 307.191 380.417 290.533 360.974 290.533Z"
      fill="#3468EF"
    />
    <path
      d="M369.67 268.056C376.522 270.879 382.852 275.13 388.8 280.845C394.713 286.56 399.409 294.576 402.817 304.786C406.261 315.101 408 326.95 408 340.401C408 354.027 406.226 366.015 402.817 376.226C399.374 386.541 394.713 394.451 388.8 400.166C382.887 405.882 376.557 410.063 369.774 412.747C362.922 415.569 355.548 416.894 347.757 416.894C340.07 416.894 332.8 415.535 326.052 412.747C319.304 410.029 312.87 405.882 306.922 400.166C301.009 394.451 296.209 386.541 292.8 376.226C289.357 366.015 287.617 354.062 287.617 340.401C287.617 326.88 289.391 314.997 292.8 304.786C296.244 294.576 301.009 286.56 306.922 280.845C312.835 275.13 319.27 270.844 326.157 268.056C332.904 265.233 340.174 263.909 347.861 263.909C355.443 263.874 362.713 265.233 369.67 268.056ZM347.861 290.533C328.417 290.533 318.678 307.191 318.678 340.401C318.678 373.612 328.452 390.269 347.861 390.269C367.304 390.269 376.939 373.612 376.939 340.401C376.939 307.191 367.27 290.533 347.861 290.533Z"
      fill="#36529C"
    />
    <path
      d="M102.191 206.129H272.174C272.174 206.129 264.73 177.066 238.678 179.575C238.678 179.575 217.6 146.713 200.209 151.766C182.852 156.819 169.183 179.575 169.183 179.575C169.183 179.575 158.017 140.405 135.687 147.967C113.357 155.564 112.104 195.989 97.2174 195.989C82.3304 195.989 74.887 207.384 102.191 206.129Z"
      fill="#F0F0F0"
    />
    <path
      d="M416.927 162.339H610.283C610.283 162.339 614.805 142.65 581.588 144.183C548.335 145.682 545.344 126.027 545.344 126.027C545.344 126.027 536.266 82.1183 500.022 85.1501C463.779 88.1819 477.379 126.027 457.727 126.027C438.074 126.027 392.753 156.311 416.927 162.339Z"
      fill="#F0F0F0"
    />
    <path
      d="M237.391 452.37C237.391 452.37 275.652 419.612 329.913 418.915C384.174 418.218 601.913 418.915 601.913 418.915L640.87 452.37C640.87 452.37 403.652 476.067 369.565 476.764C335.478 477.461 237.391 452.37 237.391 452.37Z"
      fill="#0F1B37"
    />
    <path
      d="M601.913 418.915C601.913 418.915 384.174 418.218 329.913 418.915C313.461 419.124 298.504 422.295 285.6 426.582H610.818L601.913 418.915Z"
      fill="#1C2846"
    />
    <path
      d="M588.73 418.846L624.243 454.008C634.713 452.962 640.904 452.37 640.904 452.37L601.948 418.915C601.913 418.915 597.113 418.881 588.73 418.846Z"
      fill="#1C2846"
    />
    <path
      d="M301.739 423.446C301.739 423.446 327.478 427.977 334.783 416.477C342.087 404.977 349.392 399.401 356.696 405.674C364 411.946 353.496 424.736 347.305 429.719C334.783 439.825 313.565 438.431 306.609 437.386C299.652 436.34 294.435 426.931 301.739 423.446Z"
      fill="#DEDEDE"
    />
    <path
      d="M318.331 446.515C318.331 446.515 333.426 475.683 319.513 482.652C305.6 489.622 289.635 451.846 289.635 451.846L292.313 441.531L309.913 443.901L318.331 446.515Z"
      fill="#DEDEDE"
    />
    <path
      d="M317.878 407.869C317.878 407.869 318.191 442.369 323.339 447.666C323.339 447.666 302.261 459.688 293.531 456.761C284.8 453.834 283.548 439.86 283.548 439.86C283.548 439.86 255.896 429.405 247.722 435.991L235.27 418.323L266.713 394.104L317.878 407.869Z"
      fill="#36529C"
    />
    <path
      d="M253.183 419.891C253.183 419.891 251.583 424.072 251.548 427.766C251.513 429.997 254.261 431.391 251.965 433.516C251.965 433.516 242.296 437.837 241.774 435.677C241.252 433.516 244.348 423.654 244.348 423.654L253.183 419.891Z"
      fill="#2C4384"
    />
    <path
      d="M242.122 436.2C241.913 436.096 241.774 435.922 241.704 435.713C241.183 433.587 244.174 424.073 244.278 423.655V423.62L253.113 419.822L253.252 419.752L253.217 419.891C253.217 419.926 251.617 424.108 251.583 427.767C251.583 428.569 251.93 429.3 252.278 429.963C252.87 431.113 253.426 432.193 251.965 433.552C251.687 433.691 245.461 436.444 242.852 436.34C242.574 436.34 242.296 436.305 242.122 436.2ZM252.209 430.032C251.861 429.335 251.478 428.638 251.478 427.767C251.548 424.457 252.835 420.728 253.078 419.996L244.417 423.725C244.243 424.282 241.322 433.622 241.843 435.678C241.878 435.852 242.017 435.991 242.191 436.096C244 437.002 251.652 433.622 251.93 433.482C253.322 432.158 252.8 431.182 252.209 430.032Z"
      fill="#2C4384"
    />
    <path
      d="M310.087 356.187L275.27 372.078L238.226 354.236C235.339 352.842 231.966 353.086 229.287 354.793C224.696 357.755 218.435 362.669 219.27 366.955C220.557 373.646 240.592 391.036 240.592 391.036L233.913 419.855L266.087 420.692L277.183 406.334L292.974 420.03H322.957C322.957 420.03 326.435 370.824 310.087 356.187Z"
      fill="#2D5ACC"
    />
    <path
      d="M316.452 355.594C316.452 355.594 299.27 379.5 280.939 378.977C262.644 378.455 247.896 361.971 247.896 361.971C247.896 361.971 311.513 346.185 316.452 355.594Z"
      fill="#DEDEDE"
    />
    <path
      d="M242.609 326.148C240.731 323.43 239.096 318.725 242.852 312.174C248.418 301.057 268.278 274.015 274.748 274.816C281.565 275.653 284.557 285.41 287.931 290.742C292.174 290.916 302.783 292.624 303.791 292.868C312.731 288.094 320.035 284.156 325.113 286.456C331.339 289.278 332.278 318.307 327.2 332.7C327.061 335.766 326.818 338.519 326.644 340.75C325.739 353.365 302.052 376.504 272.487 373.925C265.844 373.368 256.418 371.242 248.626 365.527C246.226 364.342 243.339 361.589 239.861 356.257C229.739 340.924 237.774 330.539 242.609 326.148Z"
      fill="#F0F0F0"
    />
    <path
      d="M243.165 334.303C243.513 334.303 243.826 334.059 243.896 333.711C243.965 333.327 243.687 332.944 243.305 332.909C243.235 332.909 237.704 331.968 232.278 327.891C231.965 327.647 231.513 327.717 231.27 328.03C231.026 328.344 231.096 328.797 231.409 329.041C237.113 333.327 242.818 334.303 243.061 334.338C243.131 334.303 243.131 334.303 243.165 334.303Z"
      fill="#353535"
    />
    <path
      d="M240.105 339.218C240.765 339.252 241.148 339.252 241.218 339.218C241.6 339.218 241.913 338.869 241.913 338.486C241.913 338.102 241.565 337.789 241.183 337.789C241.113 337.789 235.513 337.963 229.391 335.001C229.044 334.827 228.626 334.966 228.452 335.314C228.278 335.663 228.418 336.081 228.765 336.255C233.6 338.66 238.087 339.148 240.105 339.218Z"
      fill="#353535"
    />
    <path
      d="M279.652 335.557C279.687 335.557 279.722 335.557 279.757 335.557C280 335.522 285.739 334.999 291.757 331.096C292.07 330.887 292.174 330.434 291.965 330.121C291.757 329.807 291.304 329.702 290.991 329.912C285.287 333.605 279.687 334.128 279.652 334.163C279.27 334.198 278.957 334.546 278.991 334.93C278.991 335.278 279.304 335.557 279.652 335.557Z"
      fill="#353535"
    />
    <path
      d="M281.948 340.679C283.687 340.748 288.487 340.748 293.913 338.553C294.261 338.414 294.47 337.995 294.296 337.612C294.157 337.263 293.739 337.054 293.357 337.229C287.061 339.738 281.461 339.215 281.391 339.215C281.009 339.18 280.661 339.459 280.626 339.842C280.591 340.226 280.87 340.574 281.252 340.609C281.322 340.644 281.531 340.679 281.948 340.679Z"
      fill="#353535"
    />
    <path
      d="M222.191 421.25C222.191 421.25 222.261 447.212 237.218 452.022C252.174 456.831 249.183 431.67 248.348 429.44C247.513 427.14 225.67 414.106 222.191 421.25Z"
      fill="#F0F0F0"
    />
    <path
      d="M220 422.818C220.313 420.623 220.765 418.88 221.357 417.8C225.043 411.074 254.157 415.779 256.417 419.786C258.678 423.794 250.574 425.432 250.574 425.432C250.574 425.432 250.504 428.394 250.052 432.541C242.122 428.22 229.287 422.296 220 422.818Z"
      fill="#36529C"
    />
    <path
      d="M275.2 426.023C275.2 426.023 269.739 434.945 265.009 434.736C260.278 434.526 275.27 438.36 275.27 438.36C275.27 438.36 278.017 433.133 275.2 426.023Z"
      fill="#2C4384"
    />
    <path
      d="M275.235 438.43C275.2 438.43 272.383 437.698 269.6 436.897C263.93 435.294 263.965 434.98 264 434.841C264 434.736 264.139 434.632 264.974 434.667C269.6 434.876 275.061 426.059 275.096 425.989L275.165 425.885L275.2 425.989C278.017 433.029 275.304 438.326 275.27 438.395L275.235 438.43ZM265.009 434.806C264.209 434.771 264.139 434.841 264.139 434.876C264.174 435.259 269.878 436.932 275.235 438.326C275.478 437.803 277.774 432.785 275.2 426.164C274.574 427.139 269.426 435.015 265.009 434.806Z"
      fill="#2C4384"
    />
    <path
      d="M237.287 359.568C237.287 359.568 221.078 340.785 214.435 341.447C207.757 342.109 216 362.739 220.835 367.06C225.67 371.382 237.287 359.568 237.287 359.568Z"
      fill="#F0F0F0"
    />
    <path
      d="M252.696 330.713C254.444 330.713 255.861 329.294 255.861 327.542C255.861 325.791 254.444 324.371 252.696 324.371C250.948 324.371 249.531 325.791 249.531 327.542C249.531 329.294 250.948 330.713 252.696 330.713Z"
      fill="#353535"
    />
    <path
      d="M253.217 327.89C253.486 327.89 253.704 327.671 253.704 327.402C253.704 327.133 253.486 326.914 253.217 326.914C252.948 326.914 252.73 327.133 252.73 327.402C252.73 327.671 252.948 327.89 253.217 327.89Z"
      fill="white"
    />
    <path
      d="M273.913 330.505C275.661 330.505 277.078 329.085 277.078 327.333C277.078 325.582 275.661 324.162 273.913 324.162C272.165 324.162 270.748 325.582 270.748 327.333C270.748 329.085 272.165 330.505 273.913 330.505Z"
      fill="#353535"
    />
    <path
      d="M274.4 327.681C274.669 327.681 274.887 327.462 274.887 327.193C274.887 326.924 274.669 326.705 274.4 326.705C274.131 326.705 273.913 326.924 273.913 327.193C273.913 327.462 274.131 327.681 274.4 327.681Z"
      fill="white"
    />
    <path
      d="M261.913 334.164L258.991 334.025C257.774 333.955 256.765 334.931 256.765 336.151C256.765 337.858 257.322 339.949 259.896 340.089C262.296 340.193 263.374 338.486 263.861 336.883C264.278 335.558 263.305 334.234 261.913 334.164Z"
      fill="#353535"
    />
    <path
      d="M264.696 345.767C267.27 345.872 269.635 344.931 271.305 343.084C273.044 341.167 273.809 338.519 273.426 335.836C273.357 335.452 273.009 335.173 272.626 335.243C272.244 335.313 271.965 335.661 272.035 336.045C272.348 338.345 271.722 340.575 270.261 342.143C268.87 343.677 266.922 344.443 264.765 344.373C263.548 344.304 262.574 343.92 261.948 343.154C260.731 341.76 260.939 339.564 260.939 339.564C260.974 339.181 260.696 338.833 260.313 338.798C259.931 338.763 259.583 339.042 259.548 339.425C259.548 339.53 259.27 342.248 260.87 344.095C261.774 345.105 263.061 345.698 264.696 345.767Z"
      fill="#353535"
    />
    <path
      d="M255.444 344.408C255.618 344.408 255.791 344.408 255.965 344.408C257.983 344.373 259.374 343.816 260.139 342.77C261.078 341.481 260.626 339.948 260.591 339.878C260.487 339.495 260.07 339.286 259.687 339.425C259.304 339.53 259.096 339.948 259.235 340.296C259.235 340.331 259.513 341.237 258.991 341.969C258.504 342.631 257.461 342.98 255.965 343.014C251.583 343.084 250.922 339.076 250.887 338.937C250.818 338.554 250.47 338.275 250.087 338.345C249.704 338.414 249.426 338.763 249.496 339.146C249.739 340.923 251.339 344.234 255.444 344.408Z"
      fill="#353535"
    />
    <path
      d="M270.852 342.492C270.852 342.492 271.757 357.094 261.113 355.351C250.435 353.609 254.052 343.642 254.052 343.642C254.052 343.642 258.087 345.106 259.826 341.377C259.826 341.342 262.33 349.392 270.852 342.492Z"
      fill="#353535"
    />
    <path
      d="M254.018 350.507C259.791 346.185 266.609 348.729 269.705 350.298C268.487 353.434 266.052 356.152 261.078 355.351C256.87 354.654 254.887 352.632 254.018 350.507Z"
      fill="#AABBE7"
    />
    <path
      d="M323.444 292.59C327.618 297.957 328.244 312.419 324.418 319.842C324.418 319.842 311.583 296.145 307.2 295.308C307.2 295.343 317.983 285.585 323.444 292.59Z"
      fill="#DEDEDE"
    />
    <path
      d="M280.105 290.464C280.105 290.464 270.505 289.384 258.887 295.203C258.887 295.203 268 279.173 273.566 277.849C279.166 276.49 280.105 290.464 280.105 290.464Z"
      fill="#DEDEDE"
    />
    <path
      d="M476.626 426.164C484.905 429.927 526.678 424.247 543.618 417.103C542.887 414.036 542.122 412.189 542.122 412.189C520.8 394.521 499.931 400.097 480.452 414.977C478.505 416.476 476.626 418.079 474.818 419.786C474.4 422.365 473.983 424.944 476.626 426.164Z"
      fill="#DEDEDE"
    />
    <path
      d="M548.835 415.429C547.826 415.394 546.748 415.638 545.635 416.196C530.331 423.793 485.252 430.1 476.626 426.197C472.8 424.455 478.157 419.89 477.739 416.509C443.305 431.146 438.887 457.143 438.887 457.143L422.331 462.021L430.678 466.656L448.557 470.629C448.557 470.629 493.496 489.377 524.731 472.267C549.878 458.432 551.235 424.141 548.835 415.429Z"
      fill="#2D5ACC"
    />
    <path
      d="M237.391 452.369H640.87C640.87 452.369 623.478 510.915 586.609 522.763C549.739 534.612 373.044 522.763 350.087 522.763C327.13 522.763 244.348 485.824 237.391 452.369Z"
      fill="#1C2846"
    />
    <path d="M497.6 444.703H484.139V460.49H497.6V444.703Z" fill="#1C2846" />
    <path d="M529.6 444.703H516.139V460.49H529.6V444.703Z" fill="#1C2846" />
    <path
      d="M510.122 445.588C509.078 442.974 506.122 441.685 503.478 442.731C500.87 443.776 499.583 446.738 500.626 449.387L538.296 542.188L547.757 538.39L510.122 445.588Z"
      fill="#36529C"
    />
    <path
      d="M519.165 428.498C519.165 428.498 499.861 431.634 499.061 441.044C498.261 450.453 515.722 445.33 521.531 443.239"
      fill="#F0F0F0"
    />
    <path
      d="M525.565 368.488C525.565 368.488 529.983 342.212 519.722 335.521C509.461 328.83 487.687 357.72 487.687 357.72L525.565 368.488Z"
      fill="#F0F0F0"
    />
    <path
      d="M513.322 334.998C516.174 343.187 518.852 355.175 515.235 365.56L525.565 368.487C525.565 368.487 529.983 342.212 519.722 335.521C517.878 334.371 515.687 334.301 513.322 334.998Z"
      fill="#DEDEDE"
    />
    <path
      d="M475.304 377.793C475.304 377.793 471.617 368.175 477.148 359.672C484.104 348.973 494.296 343.886 519.339 348.067C544.383 352.249 556.452 367.129 557.357 379.187C558.296 391.279 557.356 408.007 548.07 416.823C538.783 425.64 490.574 427.975 478.052 416.37C465.565 404.731 459.096 385.669 475.304 377.793Z"
      fill="#F0F0F0"
    />
    <path
      d="M528.383 358.731C528.383 358.731 549.287 342.282 560.07 348.137C570.852 353.991 555.931 386.958 555.931 386.958L528.383 358.731Z"
      fill="#F0F0F0"
    />
    <path
      d="M538.4 359.674C538.4 359.674 547.687 348.975 555.548 353.645C563.444 358.28 553.705 374.554 553.705 374.554C553.705 374.554 538.4 366.643 538.4 359.674Z"
      fill="#DEDEDE"
    />
    <path
      d="M487.826 396.611C487.826 396.611 484.174 400.689 489.287 402.083C494.4 403.476 494.4 398.214 494.4 398.214"
      fill="#F0F0F0"
    />
    <path
      d="M490.887 403.338C490.296 403.338 489.67 403.233 489.009 403.059C487.165 402.536 486.018 401.63 485.635 400.375C485.009 398.25 486.852 396.124 487.061 395.915C487.444 395.497 488.105 395.462 488.522 395.845C488.939 396.228 488.974 396.891 488.591 397.309C488.278 397.692 487.374 398.947 487.618 399.783C487.826 400.445 488.765 400.828 489.531 401.038C490.713 401.351 491.618 401.281 492.244 400.828C493.113 400.166 493.322 398.703 493.322 398.18C493.322 397.622 493.809 397.135 494.365 397.135C494.957 397.135 495.409 397.588 495.409 398.18C495.409 398.284 495.374 401.038 493.496 402.466C492.8 403.059 491.931 403.338 490.887 403.338Z"
      fill="#353535"
    />
    <path
      d="M501.391 400.794C501.391 400.794 501.67 407.136 496.765 404.836C491.896 402.536 494.4 398.215 494.4 398.215"
      fill="#F0F0F0"
    />
    <path
      d="M498.713 406.37C498.018 406.37 497.218 406.16 496.348 405.777C494.574 404.941 493.426 403.756 492.974 402.257C492.244 399.923 493.496 397.797 493.565 397.692C493.844 397.204 494.505 397.03 494.991 397.309C495.478 397.588 495.652 398.25 495.374 398.738C495.374 398.738 494.539 400.201 494.991 401.63C495.27 402.536 496.035 403.303 497.252 403.86C498.191 404.313 498.922 404.383 499.374 404.069C500.278 403.477 500.418 401.456 500.383 400.794C500.348 400.201 500.8 399.748 501.391 399.713C501.948 399.679 502.435 400.132 502.47 400.724C502.47 401.107 502.574 404.453 500.522 405.812C499.965 406.195 499.374 406.37 498.713 406.37Z"
      fill="#353535"
    />
    <path
      d="M498.956 398.772C498.156 400.724 495.93 401.665 493.983 400.898C492.035 400.096 491.096 397.866 491.861 395.915C492.661 393.963 494.365 394.312 496.313 395.078C498.261 395.88 499.756 396.821 498.956 398.772Z"
      fill="#353535"
    />
    <path d="M518.783 385.459L505.218 388.944L517.044 394.52" fill="#F0F0F0" />
    <path
      d="M517.044 395.565C516.904 395.565 516.731 395.53 516.591 395.461L504.765 389.885C504.383 389.711 504.139 389.293 504.174 388.84C504.209 388.387 504.522 388.038 504.939 387.934L518.504 384.449C519.061 384.309 519.617 384.658 519.791 385.215C519.931 385.773 519.583 386.331 519.026 386.505L508.279 389.258L517.496 393.614C518.018 393.858 518.226 394.485 517.983 395.008C517.809 395.321 517.426 395.565 517.044 395.565Z"
      fill="#353535"
    />
    <path d="M484.557 373.439L493.009 384.661L480.313 381.629" fill="#F0F0F0" />
    <path
      d="M493.009 385.705C492.939 385.705 492.835 385.705 492.765 385.67L480.035 382.639C479.478 382.499 479.131 381.942 479.27 381.384C479.409 380.827 479.965 380.478 480.522 380.617L490.4 382.987L483.722 374.101C483.374 373.648 483.478 372.986 483.931 372.637C484.383 372.289 485.044 372.393 485.391 372.846L493.844 384.033C494.122 384.381 494.122 384.869 493.878 385.217C493.704 385.531 493.357 385.705 493.009 385.705Z"
      fill="#353535"
    />
    <path
      d="M276.139 391.489L276.696 391.21L303.722 378.246L316 384.519L315.757 404.034L303.687 407.763C303.722 407.763 271.026 404.487 276.139 391.489Z"
      fill="#2D5ACC"
    />
    <path
      d="M244.244 347.336L279.061 366.015C279.791 366.503 280.07 367.443 279.687 368.245L279.618 368.384C279.235 369.151 278.365 369.534 277.531 369.29L240.696 354.898L244.244 347.336Z"
      fill="#353535"
    />
    <path
      d="M216.557 327.82L250.4 349.984C252.244 351.169 252.904 353.573 251.931 355.525L251.757 355.908C250.817 357.825 248.626 358.801 246.609 358.208L207.826 346.673L216.557 327.82Z"
      fill="#36529C"
    />
    <path
      d="M216.557 327.82L207.792 346.639L214.852 348.729C217.183 346.917 219.478 343.99 221.218 340.401C222.539 337.648 223.339 334.929 223.652 332.455L216.557 327.82Z"
      fill="#AABBE7"
    />
    <path
      d="M216.556 339.504C219.101 334.216 218.989 328.879 216.307 327.584C213.625 326.288 209.388 329.525 206.843 334.812C204.299 340.1 204.41 345.437 207.092 346.733C209.775 348.028 214.012 344.792 216.556 339.504Z"
      fill="#353535"
    />
    <path
      d="M275.896 365.946C281.496 353.087 305.809 386.855 305.809 386.855L283.026 393.198C283.026 393.198 270.296 378.805 275.896 365.946Z"
      fill="#F0F0F0"
    />
    <path
      d="M281.009 391.037C281.009 391.037 265.948 365.493 278.122 363.507C287.896 361.938 306.296 386.855 306.296 386.855"
      fill="#F0F0F0"
    />
    <path
      d="M306.261 386.855C306.122 386.96 304.765 385.357 302.678 383.022C300.591 380.687 297.774 377.585 294.748 374.623C291.722 371.696 288.487 368.943 285.809 367.235C284.487 366.364 283.27 365.772 282.4 365.388C281.531 365.005 280.939 364.9 280.835 364.866C280.696 364.831 280.139 364.656 279.304 364.587C279.096 364.552 278.852 364.587 278.609 364.587C278.504 364.587 278.365 364.622 278.226 364.622C278.087 364.657 277.948 364.657 277.809 364.691C277.252 364.796 276.661 365.04 276.139 365.388C275.861 365.563 275.617 365.807 275.409 366.05C275.2 366.329 274.991 366.608 274.852 366.991C274.539 367.688 274.365 368.56 274.331 369.466C274.226 371.313 274.574 373.403 275.061 375.39C276.07 379.432 277.704 383.3 278.957 386.193C280.209 389.05 281.148 390.932 281.009 391.002C280.87 391.072 279.687 389.364 278.157 386.576C276.626 383.788 274.748 379.99 273.496 375.808C272.87 373.717 272.417 371.557 272.417 369.361C272.417 368.281 272.591 367.166 273.009 366.155C273.217 365.632 273.461 365.144 273.809 364.726C273.948 364.517 274.157 364.308 274.331 364.134C274.539 363.96 274.713 363.785 274.922 363.611C275.722 362.984 276.591 362.67 277.357 362.461C277.565 362.426 277.739 362.391 277.913 362.357C278.087 362.322 278.296 362.287 278.47 362.287C278.817 362.252 279.165 362.252 279.444 362.252C280.626 362.287 281.322 362.531 281.496 362.566C281.635 362.6 282.365 362.81 283.374 363.263C284.383 363.716 285.67 364.413 287.061 365.388C289.878 367.34 293.078 370.267 295.965 373.438C298.887 376.575 301.53 379.85 303.444 382.394C305.287 384.973 306.4 386.75 306.261 386.855Z"
      fill="#363636"
    />
    <path
      d="M207.757 337.962C208.218 337.962 208.591 337.587 208.591 337.125C208.591 336.664 208.218 336.289 207.757 336.289C207.296 336.289 206.922 336.664 206.922 337.125C206.922 337.587 207.296 337.962 207.757 337.962Z"
      fill="white"
    />
    <path
      d="M208.8 335.593C208.731 335.593 208.696 335.593 208.626 335.558C208.348 335.453 208.209 335.174 208.313 334.896C208.383 334.687 210.087 329.703 214.087 329.285C214.365 329.25 214.644 329.459 214.644 329.738C214.678 330.017 214.47 330.296 214.191 330.296C210.852 330.644 209.322 335.174 209.287 335.209C209.218 335.453 209.009 335.593 208.8 335.593Z"
      fill="white"
    />
  </svg>
);

export default SvgNotFound;
