import SvgNotFound from "components/general/svgNotFound";
import Head from "next/head";
import styled from "styled-components";

const StyledWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: center;
    max-width: 600px;
    line-height: 4rem;
    font-size: 3.375rem;
    @media screen and (max-width: 992px) {
      line-height: 2.8rem;
      font-size: 2rem;
    }
  }
  svg {
    width: 600px;
    height: auto;
    max-width: 100%;
  }
`;
const NotFound = () => {
  return (
    <>
      <Head>
        <title>Redact Not Found</title>
        <meta charSet="utf-8" />
        <link rel="icon" href="/images/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="noarchive" />
        <meta
          name="description"
          content="Easily mass delete your messages, posts and comments on Twitter, Reddit, Discord, Facebook, and more, all from one free app."
        />
        <meta name="keywords" content="redact mass delete privacy free" />

        <meta property="og:title" content="Mass delete your tweets posts & DMs on 30+ sites" />
        <meta
          property="og:description"
          content="Mass delete your messages, posts and comments on Twitter, Reddit, Discord, Facebook, and more, all from one free app."
        />

        <link rel="apple-touch-icon" href="images/logo192.png" />
        <meta property="og:url" content="https://www.redact.dev" />
      </Head>
      <StyledWrapper>
        <StyledContainer>
          <SvgNotFound />
          <h1>{`Our kitties can't find that page.`}</h1>
        </StyledContainer>
      </StyledWrapper>
    </>
  );
};

export default NotFound;
